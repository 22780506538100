<template>
  <div class="jackpot-prize white--text text-center">
    <div class="grey--text">Jackpot Prize</div>
    <h1 class="secondary--text">
      {{ moneyFormat(parseFloat(jackpotPrize)) }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    jackpotPrize: {
      required: true,
    },
  },

  methods: {
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sigmar&display=swap");

.jackpot-prize h1 {
  font-family: "Sigmar", cursive;
  font-size: 50px;
  line-height: 1.2;
}
</style>
