<template>
  <div class="main-content lucky-four-games">
    <div class="lucky-four-panel" style="position: relative">
      <div class="pa-12">
        <div
          class="lucky-four-panel-body d-flex flex-column flex-lg-row align-center justify-center"
        >
          <img src="../../../assets/img/lucky-four/lucky.png" class="lucky" />
          <img src="../../../assets/img/lucky-four/basketball.png" class="basketball" />
          <img src="../../../assets/img/lucky-four/four.png" class="four" />
          <!-- <img src="../../../assets/img/chinese_new_year/dragon-green.png" class="dragon" /> -->
          <div class="subtitle">Win a minimum of 10,000 token</div>
        </div>
        <div class="mt-10">
          <div class="bs-row">
            <div class="bs-col-sm-8 mx-auto text-center white--text">
              Lucky Four is a simple lotto-style game that involves players selecting a
              set of numbers by guessing the last digit ending score of the winning team
              of each quarter in sequential order. Winning numbers are automatically drawn
              after the game.
            </div>
          </div>
        </div>
        <div class="lucky-four-menu">
          <v-dialog ref="filter" v-model="filter" :return-value.sync="date" width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                :color="filter ? 'primary' : undefined"
                icon
                v-bind="attrs"
                v-on="on"
                dark
              >
                <v-icon>mdi-calendar-clock</v-icon>
              </v-btn>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text @click="filter = false"> Cancel </v-btn>
              <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                dark
                v-bind="attrs"
                v-on="on"
                @click="how_to_bet_dialog = true"
              >
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>How to play</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small :to="{ name: 'MyBets' }" dark>
                <v-icon>mdi-clipboard-text-outline</v-icon>
              </v-btn>
            </template>
            <span>Transactions</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="px-sm-6 px-md-8 px-lg-12 pb-4 pb-sm-6 pb-md-8 pb-lg-12">
      <div class="bs-row" v-if="!records.length && !recordsLoading">
        <div class="bs-col-md-12 bs-col-xl-12 mx-auto">
          <v-card
            class="card d-flex flex-column align-center bs-gap-2 text-center white--text pa-4 pa-sm-6"
            style="background-color: #1f4664 !important"
          >
            <div class="d-flex justify-center bs-gap-2">
              <img
                width="32"
                height="32"
                src="https://img.icons8.com/fluency/48/nothing-found.png"
                alt="nothing-found"
              />
            </div>
            Sorry, but there's no scheduled games today.
          </v-card>
        </div>
      </div>
      <div v-else>
        <Games :games="records" />
      </div>
    </div>

    <v-dialog v-model="how_to_bet_dialog" max-width="500">
      <v-card class="elevation-0">
        <div style="position: absolute; top: 0; right: 0">
          <div class="pt-4 pe-4">
            <v-btn icon small @click="how_to_bet_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <img
          src="../../../assets/img/lucky-four/how-to-bet.jpg"
          alt="How to play"
          style="width: 100%; height: auto"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Games from "@/components/app/lucky_four/Games";

export default {
  components: {
    Games,
  },

  data: () => ({
    filter: false,
    date: "",
    how_to_bet_dialog: false,
  }),
  created() {
    this.loadRecords();
  },

  computed: {
    ...mapState("lucky_four_games", ["records", "recordsLoading"]),
  },

  methods: {
    ...mapActions("lucky_four_games", ["getRecords"]),

    async loadRecords() {
      this.filter = false;

      if (!this.recordsLoading) {
        this.$store.commit("lucky_four_games/setRecords", []);

        const formData = new FormData();
        formData.append("scheduled_date_start", this.date);
        formData.append("scheduled_date_end", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },

    filterDate() {
      this.$refs.filter.save(this.date);
      this.loadRecords();
    },
  },

  watch: {
    date() {
      this.date = this.date ?? "";
    },
  },
};
</script>

<style scoped>
.lucky-four-games {
  background: url("../../../assets/img/lucky-four/lucky-four-bg.png") no-repeat center;
  background-size: cover;
}
.lucky-four-panel .lucky-four-panel-body {
  margin-bottom: -1.25rem !important;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}
.lucky-four-panel .lucky,
.lucky-four-panel .four {
  display: block;
  width: auto;
  height: 100px;
  position: absolute;
}
.lucky-four-panel .lucky {
  top: 10px;
  right: 53.5%;
  z-index: 2;
}
.lucky-four-panel .four {
  bottom: calc(10px + 1.25rem);
  left: 54.5%;
  z-index: 3;
}
.lucky-four-panel .basketball {
  display: block;
  width: auto;
  height: 200px;
  z-index: 1;
}
.lucky-four-panel .dragon {
  display: block;
  width: auto;
  height: 450px;
  z-index: 1;
  position: absolute;
}
.lucky-four-panel .subtitle {
  color: #fff;
  position: absolute;
  top: 135px;
  right: 50%;
  z-index: 2;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 0.8;
}
.lucky-four-games .lucky-four-menu {
  display: flex;
  position: absolute;
  top: 48px;
  right: 48px;
}
@media (max-width: 992px) {
  .lucky-four-panel .lucky-four-panel-body {
    margin-bottom: unset !important;
  }
  .lucky-four-panel .lucky {
    z-index: 1;
  }
  .lucky-four-panel .basketball {
    z-index: 2;
  }
  .lucky-four-panel .lucky,
  .lucky-four-panel .four,
  .lucky-four-panel .basketball,
  .lucky-four-panel .subtitle {
    position: static;
  }
  .lucky-four-panel .basketball {
    height: 120px;
  }
  .lucky-four-panel .dragon {
    height: 220px;
    top: 10px;
    z-index: 2;
  }
  .lucky-four-panel .lucky {
    height: 65px;
    margin-bottom: -30px;
  }
  .lucky-four-panel .four {
    height: 65px;
    margin-top: calc(-30px + -0.75rem);
  }
  .lucky-four-panel .subtitle {
    margin-top: 1.5rem;
    font-size: 14px;
  }
  .lucky-four-games .lucky-four-menu {
    justify-content: center;
    position: static;
    margin-top: 1rem;
  }
}
.lucky-four-games .card {
  border: 2px solid rgba(255, 255, 255, 0.5) !important ;
  box-shadow: 0 0 10px 10px rgb(255 255 255 / 5%) !important;
  transition: background-color ease 0.3s;
}
</style>
