<template>
  <div class="bet-game">
    <div style="position: relative">
      <div class="pa-6">
        <JackpotPrize :jackpotPrize="game.jackpot_prize" />

        <div
          class="d-flex flex-column align-center bs-gap-2 bs-text-center mb-4 mb-sm-6 mt-4 mt-sm-6"
        >
          <h3 class="text-uppercase font-weight-bold">{{ game.league }}</h3>
          <span class="grey--text">{{ gameTimeFormat(game.schedule) }}</span>
          <span>{{
            game.team_a_name +
            " " +
            game.team_a_alias +
            " VS " +
            game.team_b_name +
            " " +
            game.team_b_alias
          }}</span>
        </div>

        <div class="d-flex justify-center bs-gap-3">
          <div class="bs-flex flex-column align-center bs-text-center">
            <v-avatar size="80" class="light rounded-lg pa-4">
              <v-img :src="game.team_a_logo" :alt="game.team_a_name" contain>
              </v-img>
            </v-avatar>
          </div>
          <div class="bs-flex flex-column align-center bs-text-center">
            <v-avatar size="80" class="light rounded-lg pa-4">
              <v-img :src="game.team_b_logo" :alt="game.team_b_name" contain>
              </v-img>
            </v-avatar>
          </div>
        </div>

        <div class="text-center mt-4 mt-sm-6" v-if="game.is_started">
          <div class="grey--text mb-4">Result</div>

          <div class="bs-border bs-overflow-hidden rounded-lg">
            <v-simple-table dense style="pointer-events: none">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center px-4">1st</th>
                    <th class="text-center px-4">2nd</th>
                    <th class="text-center px-4">3rd</th>
                    <th class="text-center px-4">4th</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="font-weight-bold px-4">
                      {{
                        game.results[0]
                          ? game.results[0].combination_number
                          : "-"
                      }}
                    </td>
                    <td class="font-weight-bold px-4">
                      {{
                        game.results[1]
                          ? game.results[1].combination_number
                          : "-"
                      }}
                    </td>
                    <td class="font-weight-bold px-4">
                      {{
                        game.results[2]
                          ? game.results[2].combination_number
                          : "-"
                      }}
                    </td>
                    <td class="font-weight-bold px-4">
                      {{
                        game.results[3]
                          ? game.results[3].combination_number
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <div class="mt-4 mt-sm-6">
          <div class="card" v-if="game.game_status_id == null">
            <div class="text-center grey--text mb-6">
              Input your lucky four numbers
            </div>
            <div
              class="d-flex justify-space-between align-center white--text bs-gap-3"
            >
              <div class="d-flex bs-gap-3">
                <div class="d-flex flex-column flex-md-row bs-gap-3">
                  <div class="d-flex flex-column text-center bs-gap-3">
                    <input
                      id="first_pick"
                      type="number"
                      max="9"
                      v-model="first_pick"
                      @keyup="handleFirstPick"
                      class="lucky-numbers"
                      onpaste="return false;"
                    />
                    <span style="line-height: 0.8">1st</span>
                  </div>
                  <div class="d-flex flex-column text-center bs-gap-3">
                    <input
                      id="second_pick"
                      type="number"
                      max="9"
                      v-model="second_pick"
                      @keyup="handleSecondPick"
                      class="lucky-numbers"
                      onpaste="return false;"
                    />
                    <span style="line-height: 0.8">2nd</span>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row bs-gap-3">
                  <div class="d-flex flex-column text-center bs-gap-3">
                    <input
                      id="third_pick"
                      type="number"
                      max="9"
                      v-model="third_pick"
                      @keyup="handleThirdPick"
                      class="lucky-numbers"
                      onpaste="return false;"
                    />
                    <span style="line-height: 0.8">3rd</span>
                  </div>
                  <div class="d-flex flex-column text-center bs-gap-3">
                    <input
                      id="fourth_pick"
                      type="number"
                      max="9"
                      v-model="fourth_pick"
                      @keyup="handleFourthPick"
                      class="lucky-numbers"
                      onpaste="return false;"
                    />
                    <span style="line-height: 0.8">4th</span>
                  </div>
                </div>
              </div>
              <div class="d-inline-block pe-12 pe-sm-0">
                <button
                  v-ripple
                  type="button"
                  class="lucky-pick"
                  @click="generateRandomNumber"
                >
                  <span style="transform: translateY(2px)">
                    Lucky
                    <br />
                    Pick
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div
            v-else-if="
              game.game_status_id == 1 ||
              game.game_status_id == 2 ||
              game.game_status_id == 3 ||
              game.game_status_id == 4
            "
            class="card d-flex flex-column align-center bs-gap-2 text-center"
          >
            <div class="d-flex" style="gap: 5px">
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                class="fa-spin"
              />
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                class="fa-spin"
                style="transform: rotate(45deg)"
              />
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                class="fa-spin"
                style="transform: rotate(90deg)"
              />
            </div>
            The game has started, betting are now closed.
          </div>

          <div
            v-else-if="game.game_status_id == 5"
            class="card d-flex flex-column align-center bs-gap-2 text-center"
          >
            <div class="d-flex" style="gap: 5px">
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                style="filter: grayscale(100%)"
              />
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                style="filter: grayscale(100%)"
              />
              <img
                src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                height="16"
                style="filter: grayscale(100%)"
              />
            </div>
            The game has ended.
          </div>

          <div
            class="mt-12"
            v-if="
              game.is_started != 1 ||
              game.is_started != 2 ||
              game.is_started != 3 ||
              game.is_started != 4 ||
              game.is_started != 5
            "
          >
            <div class="bs-row bs-g-3">
              <div class="bs-col-6">
                <v-btn
                  :disabled="disable_reset_button || betLoading"
                  color="red"
                  block
                  depressed
                  @click="reset_bet()"
                  large
                >
                  Reset
                </v-btn>
              </div>
              <div class="bs-col-6">
                <v-btn
                  :disabled="disable_bet_button || betLoading"
                  color="primary"
                  id="lucky_four_bet"
                  @click="betConfirmDialog = true"
                  block
                  depressed
                  large
                >
                  {{ `Proceed — ${moneyFormat(parseFloat(game.bet_amount))}` }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-snackbar
      v-model="snackbarSuccess"
      top
      color="success"
      :timeout="snackbarSuccessTimeout"
    >
      <v-icon class="me-3"> mdi-check-circle-outline </v-icon>
      Success
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarSuccess = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarError"
      top
      color="error"
      :timeout="snackbarErrorTimeout"
    >
      <v-icon class="me-2"> mdi-alert-circle-outline </v-icon>
      {{ betError }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarError = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="betConfirmDialog"
      max-width="400"
      :persistent="betLoading"
    >
      <v-card class="bs-shadow-none">
        <div class="pa-6">
          <h3 class="text-uppercase secondary--text font-weight-bold mb-4">
            Confirm
          </h3>
          <v-form ref="betForm" @submit.prevent="bet">
            <div class="grey--text mb-4">
              You're about to place
              {{ moneyFormat(parseFloat(game.bet_amount)) }}
              {{ parseInt(game.bet_amount) > 1 ? "tokens" : "token" }} for
              combination
              {{
                first_pick +
                "" +
                second_pick +
                "" +
                third_pick +
                "" +
                fourth_pick
              }}, please confirm to proceed.
            </div>
            <v-text-field
              v-model="betName"
              :rules="[rules.alphaSpace, rules.maximum(100)]"
              label="Enter name (optional)"
              hide-details="auto"
              outlined
              class="mb-3"
              dense
              v-if="
                (appData && appData.account_details.agent_id) ||
                (appData &&
                  appData.account_details.investor_sub_agent_player_id &&
                  appData.account_details.investor_sub_type_id != 2 &&
                  appData.account_details.investor_type_id != 1)
              "
            >
            </v-text-field>
            <v-alert type="info" text class="my-4 my-sm-6">
              I hereby agree and understand that after clicking the "Confirm"
              button, the bets placed within the system shall be non-refundable.
              Winnings are subject to government mandated taxes.
            </v-alert>
            <div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
              <v-btn text @click="betConfirmDialog = false"> Cancel </v-btn>
              <v-btn
                ref="yes"
                color="primary"
                depressed
                type="submit"
                :loading="betLoading"
              >
                {{ `Confirm — ${moneyFormat(parseFloat(game.bet_amount))}` }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JackpotPrize from "@/components/app/lucky_four/JackpotPrize";
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDate from "@/helpers/formatDate";

export default {
  name: "BetGame",

  props: {
    game: {
      type: Object,
      required: true,
    },
  },

  components: {
    JackpotPrize,
  },

  data: () => ({
    combination: "",
    length: 4,
    betConfirmDialog: false,
    snackbarSuccess: false,
    snackbarSuccessTimeout: -1,
    snackbarError: false,
    snackbarErrorTimeout: -1,
    betName: "",
    rules: formRules,
    first_pick: "",
    second_pick: "",
    third_pick: "",
    fourth_pick: "",
  }),

  computed: {
    ...mapState("lucky_four_games", ["betError", "betHasError", "betLoading"]),
    ...mapState(["appData", "actualPoints", "geoLat", "geoLong"]),

    disable_bet_button() {
      if (
        this.first_pick == "" ||
        this.second_pick == "" ||
        this.third_pick == "" ||
        this.fourth_pick == ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    disable_reset_button() {
      if (
        this.first_pick == "" &&
        this.second_pick == "" &&
        this.third_pick == "" &&
        this.fourth_pick == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("lucky_four_games", ["doBet"]),

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    generateRandomNumber() {
      var min = 0;
      var max = 9;

      var first_pick = Math.floor(Math.random() * (max - min + 1)) + min;
      this.first_pick = first_pick ? first_pick : "0";

      var second_pick = Math.floor(Math.random() * (max - min + 1)) + min;
      this.second_pick = second_pick ? second_pick : "0";

      var third_pick = Math.floor(Math.random() * (max - min + 1)) + min;
      this.third_pick = third_pick ? third_pick : "0";

      var fourth_pick = Math.floor(Math.random() * (max - min + 1)) + min;
      this.fourth_pick = fourth_pick ? fourth_pick : "0";
    },

    reset_bet() {
      this.first_pick = "";
      this.second_pick = "";
      this.third_pick = "";
      this.fourth_pick = "";

      document.getElementById("first_pick").focus();
    },

    async bet() {
      this.snackbarSuccessTimeout = -1;
      this.snackbarErrorTimeout = -1;

      if (this.$refs.betForm.validate()) {
        if (!this.betLoading) {
          const formData = new FormData();
          const combination =
            this.first_pick +
            "" +
            this.second_pick +
            "" +
            this.third_pick +
            "" +
            this.fourth_pick;

          formData.append("game_id", this.game.game_id);
          formData.append("combination", combination);
          formData.append("bet_name", this.betName);
          formData.append("geo_lat", this.geoLat === null ? "" : this.geoLat);
          formData.append(
            "geo_long",
            this.geoLong === null ? "" : this.geoLong
          );

          await this.doBet(formData);

          this.snackbarSuccess = false;
          this.snackbarError = false;
          if (!this.betHasError) {
            this.snackbarSuccess = true;
            this.snackbarSuccessTimeout = 2000;
            this.combination = "";
            this.$emit("betConfirmed", this.game.game_id);

            // Deduct to points
            this.$store.commit(
              "setActualPoints",
              this.actualPoints - this.game.bet_amount
            );

            // Empty bet name
            this.betName = "";

            // Add bet sound
            const sound = new Audio(require("@/assets/audio/bet.wav"));
            sound.play();
          } else {
            if (this.betError) {
              this.snackbarError = true;
              this.snackbarErrorTimeout = 2000;
            }
          }

          this.betConfirmDialog = false;
          this.reset_bet();
        }
      }
    },

    format_date(date) {
      return formatDate(date, "datetime");
    },

    handleFirstPick(event) {
      if (event.key == "Backspace") {
        this.first_pick = "";
      }

      const input = this.first_pick;
      const regex = /^[0-9]+$/;

      if (regex.test(input)) {
        const pick = this.first_pick;
        if (pick.length > 1) {
          this.first_pick = event.key;
        }
        document.getElementById("second_pick").focus();
      }
    },

    handleSecondPick(event) {
      if (event.key == "Backspace") {
        this.second_pick = "";
        document.getElementById("first_pick").focus();
      }

      const input = this.second_pick;
      const regex = /^[0-9]+$/;

      if (regex.test(input)) {
        const pick = this.second_pick;
        if (pick.length > 1) {
          this.second_pick = event.key;
        }
        document.getElementById("third_pick").focus();
      }
    },

    handleThirdPick(event) {
      if (event.key == "Backspace") {
        this.third_pick = "";
        document.getElementById("second_pick").focus();
      }
      const input = this.third_pick;
      const regex = /^[0-9]+$/;

      if (regex.test(input)) {
        const pick = this.third_pick;
        if (pick.length > 1) {
          this.third_pick = event.key;
        }
        document.getElementById("fourth_pick").focus();
      }
    },

    handleFourthPick(event) {
      if (event.key == "Backspace") {
        this.fourth_pick = "";
        document.getElementById("third_pick").focus();
      }

      const input = this.fourth_pick;
      const regex = /^[0-9]+$/;

      if (regex.test(input)) {
        const pick = this.fourth_pick;
        if (pick.length > 1) {
          this.fourth_pick = event.key;
        }
        document.getElementById("lucky_four_bet").focus();
      }
    },

    gameTimeFormat(date) {
      return formatDate(date, "format", "MMM dd, Y 'at' hh:mm a");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sigmar&display=swap");

.bet-game .lucky-numbers {
  color: #000 !important;
  /* background: -webkit-radial-gradient(65% 2%, circle, #ffc82d, #ffc82d); */
  background-color: #ffc82d;
  width: 60px;
  height: 60px;
  font-family: "Sigmar", cursive;
  font-weight: normal !important;
  font-size: 24px;
  font-weight: 800;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
}

@media (max-width: 768px) {
  .bet-game .lucky-numbers {
    width: 55px;
    height: 55px;
  }
}

.bet-game .lucky-numbers::-webkit-outer-spin-button,
.bet-game .lucky-numbers::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bet-game .lucky-numbers[type="number"] {
  -moz-appearance: textfield;
}

.bet-game .lucky-pick {
  color: #ffc82d;
  width: 89px;
  height: 89px;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: 2px solid #ffc82d;
  border-radius: 50%;
  line-height: 1 !important;
  box-shadow: 0 0 5px 5px rgba(252, 215, 7, 0.15);
  transition: ease 0.3s;
}

@media (max-width: 768px) {
  .bet-game .lucky-pick {
    width: 84px;
    height: 84px;
  }
}

.bet-game .lucky-pick:hover,
.bet-game .lucky-pick:focus {
  color: #fff;
  background-color: #ffc82d;
  box-shadow: 0 0 10px 10px rgba(252, 215, 7, 0.15);
}
</style>